import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/Layout'
import { YellowButton, BlackButton } from '../components/Global/Button'
import { HeroSimpleCentered } from '../components/Global/Hero'
import { InlineLink } from '../components/Global/Link'
import SEO from '../components/Global/SEO'
import { ContentWrapper, InfoWithImageRight, InfoWithImageLeft } from '../components/Global/Content'
import SimpleCenteredTestimonial from '../components/Global/Testimonial/SimpleCentered'

const AboutPage = ({ location }) => {

  const { seoImg, mercedes, suit, judith } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "mercedes-exterior.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      mercedes: file(relativePath: { eq: "mercedes-exterior.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 980, maxHeight: 1140, cropFocus: SOUTHEAST) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      suit: file(relativePath: { eq: "man-wearing-suit.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 980, maxHeight: 1140, cropFocus: SOUTHEAST) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      judith: file(relativePath: { eq: "judith-black.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxHeight: 40, maxWidth: 40) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  
  return (
    <Layout>

      <SEO
        title="About the Chauffeur Me Team &amp; Luxury Chauffeur Services"
        description="Chauffeur Me specialises in all kinds of executive travel services, with knowledgeable, professional drivers and top of the range vehicles to transport you in luxury and comfort."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <HeroSimpleCentered>
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          About
          <br className="xl:hidden" />
          <span className="text-yellow-300"> Chauffeur Me</span>
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        Experience first class chauffeur services in Belfast with chauffeur Me. Our team specializes in providing executive travel solutions for all your transportation needs. Our knowledgeable and professional drivers will ensure you travel in luxury and comfort in our top of the range vehicles. Book your Belfast chauffeur hire today and experience the best in executive travel.
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <YellowButton to="/book-now" className="flex items-center justify-center rounded-sm shadow sm:mr-2 mb-4 sm:mb-0">Book Now</YellowButton>
          <BlackButton to="/services" className="flex items-center justify-center rounded-sm shadow sm:ml-2">Our Services</BlackButton>
        </div>
      </HeroSimpleCentered>

      <ContentWrapper backgroundColor="bg-white">
        <InfoWithImageRight
          image={mercedes.childImageSharp.fluid}
          imageAlt="Chauffeur Me executive Mercedes interior."
          noPadding
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            A Chauffeur Service You Can Trust
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
          We offer first class chauffeur services. With over 10 years of experience in the travel and transport industry, we have the knowledge and expertise to provide a seamless and luxurious transportation experience. Whether you need a professional and discreet service for business or leisure, our Belfast chauffeur hire is the perfect choice. Trust us with your travel plans and book your reservation today.
          </p>

          <ul className="mt-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Professional Chauffeurs</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    All of our drivers are experienced and friendly with great local knowledge. We also ensure our chauffeurs are immaculately presented, dressed in smart, dark suits.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3V7M3 5H7M6 17V21M4 19H8M13 3L15.2857 9.85714L21 12L15.2857 14.1429L13 21L10.7143 14.1429L5 12L10.7143 9.85714L13 3Z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Luxury Vehicles</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Chauffeur Me has a varied <InlineLink to="/our-fleet">fleet of vehicles</InlineLink> to suit all occasions and groups of different sizes. All vehicles are luxury models with air conditioning and leather seats, ideal for your comfort.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 9C15 7.89543 14.1046 7 13 7C11.8954 7 11 7.89543 11 9V14C11 15.1046 10.1046 16 9 16H15M9 12H13M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Affordable Pricing</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Your affordable, all-inclusive rate covers all taxes, tolls, fees, and gratuities. It also includes complimentary wait time — 60 minutes at airports, 15 minutes everywhere else.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </InfoWithImageRight>
      </ContentWrapper>

      <SimpleCenteredTestimonial 
        content="Chauffeur Me are the most professional transport company I have worked with. I only use them when transferring my clients, customers and VIP's. The drivers are excellent and always on time. The staff are efficient and extremely professional - I don't use anyone else."
        author="Judith Black"
        image={judith.childImageSharp.fluid}
        backgroundColor="bg-gray-50"
      />

      <ContentWrapper backgroundColor="bg-white">
        <InfoWithImageLeft
          image={suit.childImageSharp.fluid}
          imageAlt="Chauffeur Me driver dressed in a suit for the occasion."
          noPadding
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Always Putting Clients First
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            Chauffeur Me aims to provide total customer safety, comfort, and satisfaction from booking to passenger destination. Not only do we aim to meet all your requirements, but also to exceed your expectations.
          </p>

          <ul className="mt-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Personal Approach</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    The experienced and dedicated Chauffeur Me team will always ‘go the extra mile' for all our clients and deliver a very personal and professional service.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Confidentiality &amp; Privacy</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    All of our team is trained to make sure your information is safe and secure, and is committed to maintaining the highest standard of client confidentiality.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-300 text-white">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12L11 14L15 10M20.6179 5.98434C20.4132 5.99472 20.2072 5.99997 20 5.99997C16.9265 5.99997 14.123 4.84453 11.9999 2.94434C9.87691 4.84446 7.07339 5.99985 4 5.99985C3.79277 5.99985 3.58678 5.9946 3.38213 5.98422C3.1327 6.94783 3 7.95842 3 9.00001C3 14.5915 6.82432 19.2898 12 20.622C17.1757 19.2898 21 14.5915 21 9.00001C21 7.95847 20.8673 6.94791 20.6179 5.98434Z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-semibold text-gray-900">Safety</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    With our professional, insured private drivers each customer will receive a safe, high-quality, and reliable ride whether it be for business or pleasure.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </InfoWithImageLeft>
      </ContentWrapper>
      
    </Layout>
  )
}

export default AboutPage